import React from 'react'
import styled from 'styled-components'
import { useScreenView } from '@style-space/components/src/lib/mixpanel/useScreenView'
import dynamic from 'next/dynamic'
import { Seo } from '@style-space/components/src/Seo'

import { useFeaturedSessions } from '../data/hooks/Sessions/useFeaturedSessions'
import { useFeaturedStylist } from '../data/hooks/Stylist/useFeaturedStylists'
import { Page } from '../components/common/Page'
import { getSearchRoute, ROUTES } from '../utils/routes'
import { NPage } from '../../@types/types'
import { Footer } from '../components/Footer'
import { WhatWeOffer } from '../components/public/WhatWeOffer'
import { HowItWorks } from '../components/public/HowItWorks'
import { SessionGallery } from '../components/public/SessionGallery'
import { StylistsSection } from '../components/public/StylistsSection'
import { HappySessions } from '../components/public/HappySession'
import { HAPPY_MOCK } from '../utils/mock'
import { Hero } from '../components/public/Hero'
import { HeroSearch } from '../components/public/SearchBar/HeroSearch'
import { MobileSearch } from '../components/public/MobileSearch'
import { VideoSection } from '../components/public/VideoSection'

const TopPanel: any = dynamic((): any =>
  import('../components/TopPanel').then((mod) => mod.TopPanel),
)

const SPage = styled(Page)`
  max-width: none;
  margin-top: 0;
  margin-bottom: 0;
`

export const TEXT = {
  firstSection: {
    title: 'What we offer',
    text: 'Connect with expert stylists, over 1-on-1 video styling sessions for clothing, hair and makeup/skincare styling. Elevate your style, simplify getting ready and save time and money.',
    item1: {
      title: 'Clothing Styling',
      text: 'Learn the best way to dress for your body type, and discover ways to transform your look.',
    },
    item2: {
      title: 'Hair Styling',
      text: 'Get hair styling advice for any occasion, try new hairstyles, find your next cut and more.',
    },
    item3: {
      title: 'Makeup & Skincare',
      text: 'Get tailored makeup and skincare advice, master your favorite makeup looks and more.',
    },
  },
  secondSection: {
    title: 'How it works',
    button: 'LEARN MORE',
    item1: {
      title: 'Book the session',
      text: 'Book a one-on-one style session in makeup, skincare, hair or clothing.',
    },
    item2: {
      title: 'Connect with stylist',
      text: 'Connect with your expert stylist via video call and get personalized style coaching.',
    },
    item3: {
      title: 'Get a style plan',
      text: 'Receive style notes summarizing your session with product recommendations and key style takeaways.',
    },
  },
  thirdSesion: {
    title: 'Featured style sessions',
    text: 'Looking for styling help? Check out our featured style sessions',
  },
  fourthSesion: {
    title: 'The Stylists',
    text: 'Our expert stylists are passionate about helping you discover and improve your individual style. They will help you achieve your style goals and make informed decisions saving you time, money & effort.',
  },
  fifthSession: {
    title: 'Style Space happy clients',
    text: 'Read how Style Space clients improved their styles through sessions for specific life situations.',
    data: HAPPY_MOCK,
  },
}

type Props = {}

const Index: NPage<Props> = () => {
  useScreenView('HomePage')
  const {
    data: featuredData,
    nextPage: featuredNextPage,
    hasMore: featuredHasMore,
  } = useFeaturedSessions()
  const {
    data: featuredStylistData,
    nextPage: featuredStylistNextPage,
    hasMore: featuredStylistHasMore,
  } = useFeaturedStylist(5)

  return (
    <>
      <Seo />
      <TopPanel transparent />
      <SPage>
        <MobileSearch />
        <Hero>
          <HeroSearch />
        </Hero>
        <WhatWeOffer
          title={TEXT.firstSection.title}
          text={TEXT.firstSection.text}
          item1Data={{
            ...TEXT.firstSection.item1,
            href: getSearchRoute('CLOTHING'),
          }}
          item2Data={{
            ...TEXT.firstSection.item2,
            href: getSearchRoute('HAIR'),
          }}
          item3Data={{
            ...TEXT.firstSection.item3,
            href: getSearchRoute('MAKE_UP_SKIN_CARE'),
          }}
        />
        <VideoSection />
        <HowItWorks
          title={TEXT.secondSection.title}
          first={TEXT.secondSection.item1}
          second={TEXT.secondSection.item2}
          third={TEXT.secondSection.item3}
          linkText={TEXT.secondSection.button}
          linkHref={ROUTES.howItWorks}
        />
        <SessionGallery
          title={TEXT.thirdSesion.title}
          description={TEXT.thirdSesion.text}
          items={featuredData || []}
          onMore={featuredHasMore ? featuredNextPage : undefined}
        />
        <StylistsSection
          title={TEXT.fourthSesion.title}
          description={TEXT.fourthSesion.text}
          data={featuredStylistData || []}
          onMore={featuredStylistHasMore ? featuredStylistNextPage : undefined}
        />
        <HappySessions
          title={TEXT.fifthSession.title}
          description={TEXT.fifthSession.text}
          data={TEXT.fifthSession.data}
        />
      </SPage>
      <Footer bottomPadding />
    </>
  )
}
Index.displayName = 'Index'
Index.customTopPanel = true
Index.customFooter = true

export default Index
