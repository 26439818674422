import styled, { css } from 'styled-components'
import { pxToRem } from '@style-space/components/src/lib/utils/style'

import { Star } from '../icons/Star'

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const SStar = styled(Star)`
  margin-right: ${pxToRem(4)};
`

export const Value = styled.div(
  ({ theme }) => css`
    color: ${theme.color.tertiary['500']};
    font-size: ${pxToRem(14)};
    font-weight: ${theme.font.weight.bold};
    margin-right: ${pxToRem(4)};
  `,
)

export const Amount = styled.div(
  ({ theme }) => css`
    color: ${theme.color.grey['700']};
    white-space: nowrap;
    font-size: ${pxToRem(14)};
    font-weight: ${theme.font.weight.regular};
  `,
)
