import React from 'react'

import { usePagerIndex } from '../../../utils/hooks/userPagerIndex'
import { getSessionSlugRoute } from '../../../utils/routes'
import {
  Wrap,
  InnerWrap,
  Title,
  MobileTitle,
  Description,
  SHappySessionItem,
} from './HappySessions.style'
import { HappySession } from '../../../../@types/types'

type Props = {
  className?: string
  title: string
  description: string
  data: HappySession[]
}

export const HappySessions: React.FC<Props> = ({
  className,
  title,
  description,
  data,
}) => {
  const {
    index,
    onNext: onPagerNext,
    onPrev: onPagerPrev,
  } = usePagerIndex(data.length, true)

  const onNext = React.useCallback(() => {
    if (onPagerNext) {
      onPagerNext()
    }
  }, [onPagerNext])

  const onPrev = React.useCallback(() => {
    if (onPagerPrev) {
      onPagerPrev()
    }
  }, [onPagerPrev])

  return (
    <Wrap data-testid="happySessions" className={className}>
      <InnerWrap>
        <Title headingType="style2" type="h2" data-testid="happySessionsTitle">
          {title}
        </Title>
        <MobileTitle headingType="style3" type="h2">
          {title}
        </MobileTitle>
        <Description data-testid="happySessionsDescription">
          {description}
        </Description>
        {data.map(({ slug, image, headline, testimonial, id }, i) => (
          <SHappySessionItem
            className={index === i ? 'selected' : ''}
            key={id}
            onPrev={onPagerPrev && onPrev}
            onNext={onPagerNext && onNext}
            title={headline}
            text={testimonial}
            href={getSessionSlugRoute(slug)}
            image={image}
          />
        ))}
      </InnerWrap>
    </Wrap>
  )
}
HappySessions.displayName = 'HappySessions'
