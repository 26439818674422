import { usePaginatedQuery } from '@style-space/components/src/lib/graphql/usePaginatedQuery'

import { Category, Currency, SessionWithMain } from '../../../../@types/types'

const QUERY = `
  query GetFeaturedSessions($amount: Int!, $offset: Int!) {
    field: featuredSessions(pagination: { count: $amount, offset: $offset }) {
      results {
        id
        slug
        name
        description
        category
        price {
          amount
          currency
        }
        rating {
          averageScore
          numberOfRatings
        }
        mainImage
      }
      paginationInfo {
        hasMore
        totalResults
      }
    }
  }
`

type FeaturedSessionsData = {
  field: {
    results: {
      id: string
      name: string
      description: string
      category: Category
      price: {
        amount: number
        currency: Currency
      }
      rating?: {
        averageScore: number
        numberOfRatings: number
      }
      mainImage: string
    }
    paginationInfo: {
      hasMore: boolean
      totalResults: number
    }
  }[]
}

export const useFeaturedSessions = (amount: number = 24) => {
  const { data, error, ...rest } = usePaginatedQuery<
    SessionWithMain,
    FeaturedSessionsData
  >(QUERY, { amount }, (item: any) => item?.field)
  if (error) {
    throw new Error(`Featured sessions fetch failed`)
  }

  return {
    data,
    ...rest,
  }
}
