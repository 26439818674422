import React from 'react'

import { FeaturedStylist } from '../../../../@types/types'
import {
  Wrap,
  InnerWrap,
  LeftWrap,
  Title,
  Description,
  SStylistSectionItem,
  SAnchor,
} from './StylistsSection.style'

type Props = {
  data: FeaturedStylist[]
  onMore?(): void
  title: string
  description: string
  href?: string
  className?: string
}

export const StylistsSection: React.FC<Props> = ({
  onMore,
  data,
  title,
  description,
  href,
  className,
}) => {
  return (
    <Wrap data-testid="homeStylists" className={className}>
      <InnerWrap>
        <LeftWrap>
          <Title headingType="style2" type="h2" data-testid="homeStylistsTitle">
            {title}
          </Title>
          <Description data-testid="homeStylistsDescription">
            {description}
          </Description>
          {href && (
            <SAnchor uppercase={true} anchorType="button secondary regular">
              See all stylist
            </SAnchor>
          )}
        </LeftWrap>
        {data && data.length > 0 && (
          <SStylistSectionItem data={data} onMore={onMore} />
        )}
      </InnerWrap>
    </Wrap>
  )
}
StylistsSection.displayName = 'StylistsSection'
