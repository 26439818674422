import { pxToRem } from '@style-space/components/src/lib/utils'
import styled, { css } from 'styled-components'
import { Anchor } from '@style-space/components/src/Anchor'
import NextImage from 'next/image'

import { Chevron } from '../icons/Chevron'

export const Section = styled.section`
  width: 100%;
  position: relative;
`

export const InnerWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    max-width: calc(${theme.maxScreen} + ${pxToRem(40)});
    margin: 0 auto;
    height: ${pxToRem(680)};

    @media only screen and (max-width: ${pxToRem(1200)}) {
      height: ${pxToRem(600)};
    }

    @media only screen and (max-width: ${pxToRem(1075)}) {
      height: ${pxToRem(550)};
    }

    @media only screen and (max-width: ${pxToRem(850)}) {
      height: ${pxToRem(480)};
    }

    @media only screen and (max-width: ${pxToRem(760)}) {
      height: ${pxToRem(430)};
      overflow: hidden;
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      height: auto;
    }
  `,
)

export const Backgroud = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eae4e0;
`

export const ImageWrap = styled.div(
  ({ theme }) => css`
    width: 100%;
    width: ${pxToRem(908)};
    z-index: 0;
    position: absolute;
    right: ${pxToRem(-80)};
    bottom: ${pxToRem(0)};
    overflow: hidden;

    @media only screen and (max-width: ${pxToRem(1200)}) {
      width: ${pxToRem(850)};
    }

    @media only screen and (max-width: ${pxToRem(1075)}) {
      width: ${pxToRem(730)};
    }

    @media only screen and (max-width: ${pxToRem(850)}) {
      width: ${pxToRem(600)};
    }

    @media only screen and (max-width: ${pxToRem(760)}) {
      width: ${pxToRem(550)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      position: relative;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  `,
)

export const SImage = styled(NextImage)(
  () => css`
    width: 100%;
    width: 100%;
    object-fit: contain;
    height: auto;
  `,
)

export const SAnchor = styled(Anchor)(
  ({ theme }) => css`
    display: flex;
    width: max-content;
    white-space: nowrap;
    align-items: center;
    text-decoration: none;
    margin-top: ${pxToRem(40)};
    padding: ${pxToRem(10, 4)};
    position: relative;
    left: ${pxToRem(-4)};

    :hover {
      .underline {
        background-color: ${theme.color.grey['300']};
      }
      border: ${pxToRem(1)} solid transparent;
    }
    @media only screen and (max-width: ${pxToRem(1200)}) {
      margin-top: ${pxToRem(30)};
    }

    @media only screen and (max-width: ${pxToRem(1075)}) {
      margin-top: ${pxToRem(30)};
    }

    @media only screen and (max-width: ${pxToRem(850)}) {
      margin-top: ${pxToRem(22)};
    }
  `,
)

export const BigText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.font.poppins};
    font-size: ${pxToRem(64)};
    color: ${theme.color.grey['900']};
    font-weight: ${theme.font.weight.regular};
    line-height: 1.12;
    margin: 0;

    @media only screen and (max-width: ${pxToRem(1200)}) {
      font-size: ${pxToRem(48)};
    }

    @media only screen and (max-width: ${pxToRem(1075)}) {
      font-size: ${pxToRem(42)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      font-size: ${pxToRem(36)};
    }
  `,
)

export const TextWrap = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: ${pxToRem(20)};
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      position: relative;
      top: 0;
      left: 0;
      margin-top: ${pxToRem(84)};
      margin-left: ${pxToRem(20)};
      transform: translateY(0);
    }
  `,
)

export const SChevron = styled(Chevron)(
  ({ theme }) => css`
    && {
      margin-left: ${pxToRem(8)};
      margin-right: ${pxToRem(0)};
      width: ${pxToRem(24)};
      height: ${pxToRem(24)};

      path {
        stroke: ${theme.color.primary['500']};
        fill: ${theme.color.primary['500']};
      }

      @media only screen and (max-width: ${pxToRem(1075)}) {
        width: ${pxToRem(20)};
        height: ${pxToRem(20)};
      }
    }
  `,
)

export const AnchorText = styled.span(
  ({ theme }) => css`
    position: relative;
    font-family: ${theme.font.poppins};
    font-size: ${pxToRem(24)};
    font-weight: ${theme.font.weight.regular};
    color: ${theme.color.primary['500']};

    @media only screen and (max-width: ${pxToRem(1200)}) {
      font-size: ${pxToRem(20)};
    }

    @media only screen and (max-width: ${pxToRem(1075)}) {
      font-size: ${pxToRem(16)};
    }
  `,
)

export const Underline = styled.div`
  position: absolute;
  bottom: ${pxToRem(12)};
  width: calc(100% - ${pxToRem(4)});
  height: ${pxToRem(8)};
  background-color: #d3cdc9;
`
