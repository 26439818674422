import React from 'react'

import { HowItWorksItem } from './HowItWorksItem/HowItWorksItem'
import { Wrap, Title, ItemWrap, LinkButton } from './HowItWorks.style'
import bookSession from '../../../../public/images/how-it-works-first.jpg'
import aboutMission from '../../../../public/images/how-it-works-second.jpg'
import feelSession from '../../../../public/images/how-it-works-third.jpg'

type Props = {
  title: string
  first: {
    title: string
    text: string
  }
  second: {
    title: string
    text: string
  }
  third: {
    title: string
    text: string
  }
  linkText: string
  linkHref: string
  className?: string
}

export const HowItWorks: React.FC<Props> = ({
  title,
  first: { title: firstTitle, text: firstText },
  second: { title: secondTitle, text: secondText },
  third: { title: thirdTitle, text: thirdText },
  linkHref,
  linkText,
  className,
}) => {
  return (
    <Wrap data-testid="HowItWorks" className={className}>
      <Title headingType="style2" type="h2" data-testid="HowItWorksTitle">
        {title}
      </Title>
      <ItemWrap>
        <HowItWorksItem src={bookSession} title={firstTitle} text={firstText} />
        <HowItWorksItem
          src={aboutMission}
          title={secondTitle}
          text={secondText}
        />
        <HowItWorksItem src={feelSession} title={thirdTitle} text={thirdText} />
      </ItemWrap>
      <LinkButton
        nextLink
        href={linkHref}
        anchorType="button primary regular"
        aria-label={`${title} ${linkText}`}
        data-testid="HowItWorksLink"
      >
        {linkText}
      </LinkButton>
    </Wrap>
  )
}
HowItWorks.displayName = 'HowItWorks'
