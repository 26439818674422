import React from 'react'

import { usePagerIndex } from '../../../../utils/hooks/userPagerIndex'
import { FeaturedStylist } from '../../../../../@types/types'
import { getStylistSlugRoute } from '../../../../utils/routes'
import { StylistSectionItemDisplay } from './StylistSectionItemDisplay/StylistSectionItemDisplay'
import { SPager, Wrap } from './StylistSectionItems.style'

type Props = {
  data: FeaturedStylist[]
  onMore?(): void
  className?: string
}

export const StylistSectionItems: React.FC<Props> = ({
  onMore,
  data,
  className,
}) => {
  const {
    index,
    onNext: onPagerNext,
    onPrev: onPagerPrev,
  } = usePagerIndex(data.length, false)
  const moreLock = React.useRef(false)
  const { slug, fullName, avatar, about, category } = data[index] || {}
  const href = getStylistSlugRoute(slug)

  const callForMoreItems = React.useCallback(() => {
    if (moreLock.current) {
      return
    }
    moreLock.current = true
    if (onMore) {
      onMore()
    }
  }, [onMore, moreLock])

  React.useEffect(() => {
    moreLock.current = false
  }, [data])

  const onNext = React.useCallback(() => {
    if (data.length <= index + 2 && onMore) {
      callForMoreItems()
    }
    if (onPagerNext) {
      onPagerNext()
    }
  }, [data, index, callForMoreItems, onPagerNext, onMore])

  const onPrev = React.useCallback(() => {
    if (onPagerPrev) {
      onPagerPrev()
    }
  }, [onPagerPrev])

  if (data.length === 0) {
    return <Wrap data-testid="StylistSectionItems" className={className} />
  }

  return (
    <Wrap data-testid="StylistSectionItems" className={className}>
      <StylistSectionItemDisplay
        avatar={avatar}
        category={category}
        fullName={fullName}
        href={href}
        about={about}
      />
      <SPager
        white
        onPrev={onPagerPrev && onPrev}
        onNext={onPagerNext && onNext}
      />
    </Wrap>
  )
}
StylistSectionItems.displayName = 'StylistSectionItems'
