import React from 'react'

import { SSearchBar } from './HeroSearch.style'

export const ID = 'hero-search'
export const CLASSNAME = 'sticky'

type Props = {
  className?: string
}

export const HeroSearch: React.FC<Props> = ({ className = '' }) => {
  const [sticky, setSticky] = React.useState(0)
  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const listener = () => {
      const elem = document.getElementById(ID)
      if (!elem) {
        return
      }
      const rect = elem.getBoundingClientRect()
      if (!sticky && rect.top < 0) {
        setSticky(window.scrollY)
      }
      if (sticky > window.scrollY) {
        setSticky(0)
      }
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [sticky])
  const stickyClass = sticky ? CLASSNAME : ''
  return <SSearchBar className={`${stickyClass} ${className}`} id={ID} />
}
HeroSearch.displayName = 'HeroSearch'
