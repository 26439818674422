import React from 'react'
import { formatPrice } from '@style-space/components/src/lib/price'
import { pxToRem } from '@style-space/components/src/lib/utils/style'

import { Rating } from '../../Rating/Rating'
import { Currency } from '../../../../../@types/types'
import {
  Link,
  SS3NextImage,
  DataWrap,
  DataInner,
  Price,
  PriceInfo,
  Category,
  SRatioWrap,
  Title,
} from './SessionGalleryItem.style'

const RATIO = 132

type Props = {
  href: string
  src: string
  category: string
  title: string
  currency?: Currency
  price: number
  ratingValue: number
  ratingAmount: number
  className?: string
}

export const SessionGalleryItem: React.FC<Props> = ({
  href,
  className,
  src,
  price,
  currency = 'USD',
  category,
  ratingAmount,
  ratingValue,
  title,
}) => {
  return (
    <Link data-testid="sessionItem" href={href} className={className}>
      <SRatioWrap ratio={RATIO}>
        <SS3NextImage
          src={src}
          alt={`${title} main picture`}
          data-testid="s3ImageImage"
          sizes={`${pxToRem(282)}`}
        />
      </SRatioWrap>
      <DataWrap>
        <DataInner>
          <Price data-testid="sessionItemPrice">
            {formatPrice(currency, price)}
          </Price>
          <PriceInfo>per session</PriceInfo>
        </DataInner>
        {ratingAmount > 0 && (
          <Rating value={ratingValue} amount={ratingAmount} />
        )}
      </DataWrap>
      <Category data-testid="sessionItemCategory">{category}</Category>
      <Title headingType="style5" type="h3" data-testid="sessionItemTitle">
        {title}
      </Title>
    </Link>
  )
}
SessionGalleryItem.displayName = 'SessionGalleryItem'
