import { StaticImageData } from 'next/image'
import React from 'react'

import {
  Wrap,
  Title,
  InnerWrap,
  SPager,
  DataWrap,
  ScreenWrap,
  SNextImage,
  Quotes,
  Description,
  SButtonLink,
} from './HappySessionItem.style'

type Props = {
  onNext?(): void
  onPrev?(): void
  title: string
  text: string
  href: string
  image: StaticImageData
  className?: string
}

export const HappySessionItem: React.FC<Props> = ({
  title,
  onNext,
  onPrev,
  text,
  href,
  image,
  className,
}) => {
  return (
    <Wrap data-testid="HappySessionItem" className={className}>
      <InnerWrap>
        <SNextImage data-testid="HappySessionItemImage" src={image} alt={''} />
        <SPager onPrev={onPrev} onNext={onNext} />
      </InnerWrap>
      <DataWrap>
        <ScreenWrap>
          <Title
            headingType="style5"
            type="h3"
            data-testid="HappySessionItemTitle"
          >
            {title}
          </Title>
        </ScreenWrap>
        <Quotes>“</Quotes>
        <Description data-testid="HappySessionItemDescription">
          {text}
        </Description>
        <Quotes>„</Quotes>
        <SButtonLink
          anchorType="button secondary regular"
          data-testid="HappySessionItemLink"
          href={href}
        >
          GO TO THE SESSION
        </SButtonLink>
      </DataWrap>
    </Wrap>
  )
}
HappySessionItem.displayName = 'HappySessionItem'
