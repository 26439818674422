import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import { object, string } from '@style-space/components/src/Form/schema'

import { getSearchRoute } from '../../../utils/routes'
import {
  Background,
  ButtonBg,
  ButtonText,
  PopoverButtonText,
  PopoverItems,
  SButton,
  SForm,
  SFormikRadioGroup,
  SMagnifier,
  SPopoverButton,
  Title,
  XButton,
  XIcon,
} from './MobileSearch.style'
import { Category, Gender } from '../../../../@types/types'

export const CATEGORY_OPTIONS = [
  { label: 'Clothing', value: 'CLOTHING' },
  { label: 'Hair', value: 'HAIR' },
  { label: 'Makeup/Skincare', value: 'MAKE_UP_SKIN_CARE' },
]
export const GENDER_OPTIONS = [
  { label: 'Any', value: 'ANY' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
]

export const SCHEMA = object().shape({
  category: string(),
  gender: string(),
})

export const INIT_VALUES = {
  category: 'CLOTHING' as Category,
  gender: 'ANY' as Gender,
}

export type SearchValues = typeof INIT_VALUES

type Props = {
  values?: SearchValues
}

export const MobileSearch: React.FC<Props> = ({ values }) => {
  const router = useRouter()
  const onSubmit = React.useCallback(
    (close: () => void) =>
      async ({ category, gender }: SearchValues) => {
        close()
        await router.push(getSearchRoute(category, gender))
      },
    [router],
  )
  return (
    <Popover>
      {({ open, close }) => (
        <>
          <Background />
          <SPopoverButton
            data-testid="tag-select-button"
            className={`${open ? 'open ' : ''}tag-select-button`}
          >
            <SMagnifier />
            <PopoverButtonText>Find your style session</PopoverButtonText>
          </SPopoverButton>
          <Transition
            as={Fragment}
            show={open}
            enter="enter"
            enterFrom="enter-from"
            enterTo="enter-to"
            leave="enter"
            leaveFrom="enter-to"
            leaveTo="enter-from"
          >
            <PopoverItems>
              <SForm<SearchValues>
                initialValues={{ ...INIT_VALUES, ...values }}
                validationSchema={SCHEMA}
                customSubmit
                onSubmit={onSubmit(close)}
                data-testid="searchForm"
              >
                <XButton type="button" onClick={() => close()}>
                  <XIcon />
                </XButton>
                <Title>Find your style session</Title>
                <SFormikRadioGroup
                  popError
                  radioType="chip"
                  name="category"
                  label="Category"
                  options={CATEGORY_OPTIONS}
                />
                <SFormikRadioGroup
                  popError
                  radioType="chip"
                  name="gender"
                  label="Gender"
                  options={GENDER_OPTIONS}
                />
                <SButton data-testid="searchSubmit" type="submit">
                  <ButtonText>VIEW STYLE SESSIONS</ButtonText>
                  <ButtonBg />
                </SButton>
              </SForm>
            </PopoverItems>
          </Transition>
        </>
      )}
    </Popover>
  )
}
MobileSearch.displayName = 'MobileSearch'
