import { Heading } from '@style-space/components/src/Heading'
import styled, { css } from 'styled-components'
import NextImage from 'next/image'
import { pxToRem } from '@style-space/components/src/lib/utils/style'

export const Wrap = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: auto;
    max-width: ${pxToRem(348)};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      max-width: none;
      margin: ${pxToRem(0, 0, 32)};
    }
  `,
)

export const SNextImage = styled(NextImage)(
  ({ theme }) => css`
    border-radius: ${theme.radius.small};
    width: 100%;
    max-width: ${pxToRem(348)};
    height: auto;
    max-height: ${pxToRem(196)};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      max-width: 100%;
      max-height: 100%;
    }
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    margin: ${pxToRem(24, 0, 12)};
    width: 100%;
    text-align: center;
    color: ${theme.color.white};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      && {
        margin: ${pxToRem(16, 0, 8)};
        font-size: ${pxToRem(18)};
      }
    }
  `,
)

export const Text = styled.p(
  ({ theme }) => css`
    margin: 0;
    width: 100%;
    color: ${theme.color.grey['300']};
    text-align: center;
    font-size: ${pxToRem(16)};
    line-height: 1.5;
  `,
)
