import styled, { css } from 'styled-components'
import { Heading } from '@style-space/components/src/Heading'

import { HappySessionItem } from './HappySessionItem/HappySessionItem'
import { pxToRem } from '../../../utils/utils'

export const Wrap = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: ${pxToRem(96, 20)};
    background-color: ${theme.color.white};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      padding: ${pxToRem(56, 20)};
    }
  `,
)

export const InnerWrap = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${pxToRem(1248)};
`

export const Title = styled(Heading)(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    color: ${theme.color.grey['900']};
    margin: ${pxToRem(0, 0, 12)};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      display: none;
    }
  `,
)

export const MobileTitle = styled(Heading)(
  ({ theme }) => css`
    && {
      display: none;
      font-size: ${pxToRem(28)};
      margin-bottom: ${pxToRem(12)};
      @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
        display: block;
      }
    }
  `,
)

export const Description = styled.p(
  ({ theme }) => css`
    display: block;
    margin: ${pxToRem(0, 0, 48)};
    width: 100%;
    color: ${theme.color.grey['500']};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin: ${pxToRem(0, 0, 32)};
    }
  `,
)

export const SHappySessionItem = styled(HappySessionItem)`
  display: none;
  &.selected {
    display: flex;
  }
`
