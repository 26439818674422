import React from 'react'
import styled, { css } from 'styled-components'

const Svg = styled.svg(
  ({ theme }) => css`
    path {
      stroke: ${theme.color.tertiary['500']};
      fill: ${theme.color.tertiary['500']};
    }
  `,
)

type Props = {
  className?: string
}

export const Star: React.FC<Props> = ({ className }) => {
  return (
    <Svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M7.04894 2.92705C7.3483 2.00574 8.6517 2.00574 8.95106 2.92705L9.5716 4.83688C9.70547 5.2489 10.0894 5.52786 10.5227 5.52786H12.5308C13.4995 5.52786 13.9023 6.76748 13.1186 7.33688L11.494 8.51722C11.1435 8.77187 10.9968 9.22323 11.1307 9.63525L11.7512 11.5451C12.0506 12.4664 10.9961 13.2325 10.2124 12.6631L8.58778 11.4828C8.2373 11.2281 7.7627 11.2281 7.41221 11.4828L5.78761 12.6631C5.0039 13.2325 3.94942 12.4664 4.24878 11.5451L4.86932 9.63526C5.00319 9.22323 4.85653 8.77186 4.50604 8.51722L2.88144 7.33688C2.09773 6.76748 2.50051 5.52786 3.46923 5.52786H5.47735C5.91057 5.52786 6.29453 5.2489 6.4284 4.83688L7.04894 2.92705Z" />
    </Svg>
  )
}
Star.displayName = 'Star'
