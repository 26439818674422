import styled, { css } from 'styled-components'

import { pxToRem } from '../../../../../../utils/utils'
import { RatioWrap } from '../../../../RatioWrap'
import { NextLink } from '../../../../../common/NextLink'

export const SRatioWrap = styled(RatioWrap)(
  ({ theme }) => css`
    border-radius: ${theme.radius.small};
    overflow: hidden;
  `,
)

export const Wrap = styled(NextLink)(
  ({ theme }) => css`
    text-decoration: none;
    border-radius: ${theme.radius.small};
    overflow: hidden;
    position: relative;
    width: 100%;
    height: min-content;
    margin-bottom: ${pxToRem(48)};
    display: flex;
    justify-items: flex-end;
    flex-direction: column;
    align-items: center;
  `,
)

export const TitleWrap = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-top: ${pxToRem(16)};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
`

export const SubTitle = styled.h5(
  ({ theme }) => css`
    font-size: ${pxToRem(16)};
    color: ${theme.color.white};
    font-weight: ${theme.font.weight.bold};
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
    width: 100%;
    text-align: center;

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      font-size: ${pxToRem(10)};
    }
  `,
)

export const Title = styled.h4(
  ({ theme }) => css`
    font-family: ${theme.font.poppins};
    font-size: ${pxToRem(32)};
    color: ${theme.color.white};
    line-height: 1.2;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 1rem);
    font-weight: ${theme.font.weight.regular};
    margin: ${pxToRem(4, 8, 16)};

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      font-size: ${pxToRem(16)};
    }
  `,
)
