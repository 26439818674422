import styled, { css } from 'styled-components'
import { Heading } from '@style-space/components/src/Heading'
import { Anchor } from '@style-space/components/src/Anchor'

import { StylistSectionItems } from './StylistSectionItems/StylistSectionItems'
import { pxToRem } from '../../../utils/utils'

export const Wrap = styled.div(
  ({ theme }) => css`
    position: relative;
    min-height: ${pxToRem(588)};
    width: 100%;
    padding: ${pxToRem(96, 20)};
    background-color: ${theme.color.grey['800']};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      padding: ${pxToRem(56, 20)};
    }
  `,
)

export const InnerWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0px auto;
    width: 100%;
    max-width: ${pxToRem(1248)};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      flex-direction: column;
      ${LeftWrap} {
        margin-bottom: ${pxToRem(32)};
      }
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding-bottom: ${pxToRem(80)};
    }
  `,
)

export const LeftWrap = styled.div(
  ({ theme }) => css`
    max-width: ${pxToRem(426)};
    width: 100%;
    padding-right: ${pxToRem(20)};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      max-width: none;
    }
  `,
)

export const SStylistSectionItem = styled(StylistSectionItems)`
  width: 100%;
  > div:first-of-type {
    justify-content: flex-start;
  }
`

export const Title = styled(Heading)(
  ({ theme }) => css`
    && {
      color: ${theme.color.white};
      margin: ${pxToRem(0, 0, 12)};
      @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
        font-size: ${pxToRem(28)};
      }
    }
  `,
)

export const Description = styled.p(
  ({ theme }) => css`
    font-size: ${pxToRem(16)};
    color: ${theme.color.grey['300']};
    line-height: 1.5;
    margin-bottom: ${pxToRem(24)};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      max-width: 100%;
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      margin-bottom: 0;
    }
  `,
)

export const SAnchor = styled(Anchor)(
  ({ theme }) => css`
    && {
      border: ${pxToRem(1)} solid ${theme.color.grey['600']};
      background-color: ${theme.color.grey['800']};
      color: ${theme.color.grey['200']};
      line-height: 1.5;
      margin: 0;
      :hover {
        background-color: ${theme.color.darkOpa};
        color: ${theme.color.grey['200']};
      }
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        position: absolute;
        bottom: ${pxToRem(56)};
        max-width: calc(100% - ${pxToRem(40)});
        width: calc(100% - ${pxToRem(40)});
      }
    }
  `,
)
