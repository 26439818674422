import React from 'react'
import { S3NextImage } from '@style-space/components/src/S3NextImage'

import {
  Wrap,
  TitleWrap,
  SubTitle,
  Title,
  SRatioWrap,
} from './StylistImage.style'

type Props = {
  image: string
  subtitle: string
  title: string
  href: string
  className?: string
}

const RATIO = 132

export const StylistImage: React.FC<Props> = ({
  href,
  image,
  subtitle,
  title,
  className,
}) => {
  return (
    <Wrap href={href} data-testid="StylistImage" className={className}>
      <SRatioWrap ratio={RATIO}>
        <S3NextImage
          data-testid="StylistImageImg"
          alt={`${title} image number`}
          src={image}
        />
      </SRatioWrap>
      <TitleWrap>
        <SubTitle data-testid="StylistImageSubtitle">{subtitle}</SubTitle>
        <Title data-testid="StylistImageTitle">{title}</Title>
      </TitleWrap>
    </Wrap>
  )
}
StylistImage.displayName = 'StylistImage'
