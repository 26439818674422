import React from 'react'

import { CATEGORIES_MAP } from '../../../utils/constants'
import { SessionWithMain } from '../../../../@types/types'
import { getSessionSlugRoute } from '../../../utils/routes'
import {
  Section,
  Line,
  TitleWrap,
  Title,
  SPager,
  Description,
  ScrollWrap,
  RatioWrap,
  InnerWrap,
  SSessionItem,
} from './SessionGallery.style'

const VISIBLE = 4
const FETCH_OFFSET = 400

type Props = {
  title: string
  description?: string
  onMore?(): void
  line?: boolean
  items: SessionWithMain[]
  className?: string
}

export const SessionGallery: React.FC<Props> = ({
  className,
  items,
  line,
  onMore,
  title,
  description,
}) => {
  const [index, setIndex] = React.useState(0)
  const moreLock = React.useRef(false)
  const scrollWrap = React.useRef<HTMLDivElement | null>(null)

  const callForMoreItems = React.useCallback(() => {
    if (moreLock.current) {
      return
    }
    moreLock.current = true
    if (onMore) {
      onMore()
    }
  }, [onMore, moreLock])

  React.useEffect(() => {
    moreLock.current = false
  }, [items])

  const onNext = React.useCallback(() => {
    if (items.length <= (index + 2) * VISIBLE && onMore) {
      callForMoreItems()
    }
    setIndex((prev) => prev + 1)
  }, [items, index, callForMoreItems, setIndex])
  const onPrev = React.useCallback(() => {
    setIndex((prev) => {
      const newValue = prev - 1
      return newValue < 0 ? 0 : newValue
    })
  }, [setIndex])
  const isNext =
    Boolean(onMore) || Math.ceil(items.length / VISIBLE) > index + 1
  const isPrev = index > 0
  const groupedItems = items.reduce((res, item, index) => {
    if (index % VISIBLE === 0) {
      return [...res, [item]]
    }
    const ret = [...res]
    ret[ret.length - 1].push(item)
    return ret
  }, [] as SessionWithMain[][])

  React.useEffect(() => {
    if (!scrollWrap.current) {
      return
    }
    const fn = () => {
      if (!scrollWrap.current) {
        return
      }
      const { offsetWidth, scrollLeft, scrollWidth } = scrollWrap.current
      if (offsetWidth + scrollLeft >= scrollWidth - FETCH_OFFSET * VISIBLE) {
        callForMoreItems()
      }
    }
    scrollWrap.current.addEventListener('scroll', fn)
    return () => {
      if (scrollWrap.current) {
        scrollWrap.current?.removeEventListener('scroll', fn)
      }
    }
  }, [scrollWrap.current, callForMoreItems])

  const transform = {
    transform: `translateX(-${index * 100}%) translateX(-${index * 1.875}rem)`,
  }

  return (
    <Section data-testid="sessionGallery" className={className}>
      {line && <Line />}
      <TitleWrap>
        <Title headingType="style2" type="h2" data-testid="sessionGalleryTitle">
          {title}
        </Title>
        <SPager
          onNext={isNext ? onNext : undefined}
          onPrev={isPrev ? onPrev : undefined}
        />
      </TitleWrap>
      {description && (
        <Description data-testid="sessionGalleryDescription">
          {description}
        </Description>
      )}
      <ScrollWrap ref={scrollWrap}>
        <RatioWrap>
          {groupedItems.map((items, i) => (
            <InnerWrap
              style={transform}
              amount={items.length}
              key={`innerWrap-${i}`}
            >
              {items.map(
                ({
                  id,
                  slug,
                  name,
                  rating,
                  category,
                  price: { amount, currency },
                  mainImage,
                }) => (
                  <SSessionItem
                    key={id}
                    href={getSessionSlugRoute(slug)}
                    src={mainImage}
                    title={name}
                    currency={currency}
                    ratingValue={rating?.averageScore || 0}
                    ratingAmount={rating?.numberOfRatings || 0}
                    price={amount}
                    category={CATEGORIES_MAP[category]}
                  />
                ),
              )}
            </InnerWrap>
          ))}
        </RatioWrap>
      </ScrollWrap>
    </Section>
  )
}
SessionGallery.displayName = 'SessionGallery'
