import { Button } from '@style-space/components/src/Button'
import { Form } from '@style-space/components/src/Form'
import { FormikRadioGroup } from '@style-space/components/src/Form/Radio/RadioGroup/FormikRadioGroup'
import { pxToRem } from '@style-space/components/src/lib/utils'
import styled, { css } from 'styled-components'

import { Magnifier } from '../../common/icons/Magnifier'

export const Wrap = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: ${theme.maxScreen};
    height: ${pxToRem(112)};
    background-color: ${theme.color.white};
    justify-content: space-between;
    border-radius: ${theme.radius.big};
    box-shadow: ${theme.boxShadow.default};

    @media only screen and (max-width: ${pxToRem(1150)}) {
      max-width: none;
      border-radius: 0;
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)

export const InnerWrap = styled.div(
  ({ theme }) => css`
    margin: 0 auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    max-width: ${theme.breakpoint.laptop};
  `,
)

export const Title = styled.h5(
  ({ theme }) => css`
    white-space: nowrap;
    font-size: ${pxToRem(18)};
    line-height: 1.2;
    width: ${pxToRem(98)};
    flex: none;
    font-weight: ${theme.font.weight.medium};
    margin: ${pxToRem(0, 32)};
    background: linear-gradient(
      86.22deg,
      #28388c 0%,
      #3091b8 46.87%,
      #72288c 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      display: none;
    }
  `,
)

export const SButton = styled(Button)(
  ({ theme }) => css`
    && {
      display: flex;
      align-items: center;
      color: ${theme.color.white};
      position: relative;
      overflow: hidden;
      width: 100%;
      max-width: ${pxToRem(280)};
      height: ${pxToRem(72)};
      border-radius: ${theme.radius.medium};
      background-color: transparent;
      border: none;
      margin-right: ${pxToRem(20)};
      padding: ${pxToRem(0, 20)};

      :hover {
        background-color: transparent;
        border-color: transparent;

        > div {
          animation: anime-hover 1s;
          background-position: 100% 50%;
        }
      }

      svg {
        margin: 0;
      }

      @keyframes anime-hover {
        0% {
          background-position: 0 50%;
        }
        100% {
          background-position: 100% 50%;
        }
      }

      @media only screen and (max-width: ${pxToRem(1150)}) {
        max-width: ${pxToRem(104)};
      }
    }
  `,
)

export const ButtonText = styled.span`
  font-size: inherit;
  color: inherit;
  position: relative;
  margin-left: ${pxToRem(12)};
  text-transform: uppercase;
  line-height: 1;
  z-index: 1;
  @media only screen and (max-width: ${pxToRem(1150)}) {
    display: none;
  }
`

export const ButtonBg = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    86.22deg,
    #28388c 0%,
    #3091b8 46.87%,
    #72288c 100%
  );
  animation: anime 16s linear infinite;
  background-size: 600%;

  @keyframes anime {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`

export const ScreenWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)

export const Line = styled.div(
  ({ theme }) => css`
    width: 0.0625rem;
    height: ${pxToRem(72)};
    background-color: ${theme.color.gray};

    .small & {
      height: ${pxToRem(56)};
    }
  `,
)

export const SMagnifier = styled(Magnifier)(
  ({ theme }) => css`
    position: relative;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    z-index: 1;
    flex: none;
    stroke: ${theme.color.white};
  `,
)

export const SForm: typeof Form = styled(Form)`
  position: relative;
  width: 100%;
  height: 100%;
` as any

export const SFormikRadioGroup = styled(FormikRadioGroup)(
  ({ theme }) => css`
    margin: ${pxToRem(0, 'auto')};
    padding: ${pxToRem(0, 8)};

    fieldset {
      margin-top: 0;
    }

    .radio-group-label {
      font-size: ${pxToRem(16)};
      color: ${theme.color.grey['500']};
      font-weight: ${theme.font.weight.regular};
    }

    .radio-group-container {
      flex-wrap: nowrap;
      margin: 0;
    }

    .radio-input {
      margin: 0;

      label {
        margin-right: ${pxToRem(12)};
      }
      :last-of-type {
        label {
          margin-right: 0;
        }
      }
    }
  `,
)
