import styled, { css } from 'styled-components'
import { Heading } from '@style-space/components/src/Heading'
import { Anchor } from '@style-space/components/src/Anchor'
import NextImage from 'next/image'

import { Pager } from '../../Pager/Pager'
import { pxToRem } from '../../../../utils/utils'

export const Wrap = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: ${theme.color.white};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      flex-direction: column;
    }
  `,
)

export const ScreenWrap = styled.div`
  display: block;
`

export const SPager = styled(Pager)(
  ({ theme }) => css`
    margin-top: ${pxToRem(24)};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      && {
        margin: ${pxToRem(33, 0)};
      }
    }
  `,
)

export const InnerWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    flex: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: ${pxToRem(32)};
    max-width: ${pxToRem(534)};
    width: 100%;
    @media only screen and (max-width: ${theme.breakpoint.laptop}) {
      max-width: ${pxToRem(400)};
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin-right: 0;
      max-width: none;
    }
  `,
)

export const DataWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    margin: auto ${pxToRem(0, 52, 0)};
    max-width: ${pxToRem(604)};
    height: calc(100% - ${pxToRem(52)});
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin-right: 0;
      margin-bottom: 0;
      max-width: none;
    }
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    transition: 300ms opacity ease-in-out;
    overflow: hidden;
    opacity: 1;
    margin: ${pxToRem(0, 0, 24)};
    width: 100%;
    color: ${theme.color.grey['900']};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin: ${pxToRem(0, 0, 52)};
    }
  `,
)

export const SButtonLink = styled(Anchor)(
  ({ theme }) => css`
    margin-top: 3rem;
    max-width: 15rem;
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      && {
        margin-top: ${pxToRem(52)};
        max-width: 100%;
      }
    }
  `,
)

export const Description = styled.p(
  ({ theme }) => css`
    position: relative;
    overflow: hidden;
    transition: 300ms opacity ease-in-out;
    opacity: 1;
    margin: ${pxToRem(8, 0)};
    width: 100%;
    color: ${theme.color.grey['500']};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
  `,
)

export const Quotes = styled.p(
  ({ theme }) => css`
    transition: 300ms opacity ease-in-out;
    opacity: 1;
    margin: 0;
    color: ${theme.color.grey['500']};
    font-size: ${pxToRem(32)};
    line-height: 0;
    :first-of-type {
      margin-top: ${pxToRem(28)};
    }
    :last-of-type {
      margin-bottom: ${pxToRem(28)};
    }
  `,
)

export const SNextImage = styled(NextImage)(
  ({ theme }) => css`
    overflow: hidden;
    width: 100%;
    object-fit: contain;
    height: ${pxToRem(390)};
    opacity: 1;
    > div {
      top: 50%;
      transform: translateY(-50%);
      border-radius: ${theme.radius.small};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      height: auto;
      > div {
        top: 0;
        transform: none;
      }
    }
  `,
)
