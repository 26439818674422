import React from 'react'
import styled, { css } from 'styled-components'

const Svg = styled.svg(
  ({ theme }) => css`
    path {
      fill: ${theme.color.grey['500']};
    }

    &.right {
      transform: rotate(180deg);
    }
  `,
)

type Props = {
  direction?: 'left' | 'right'
  className?: string
}

export const Chevron: React.FC<Props> = ({
  direction = 'left',
  className = '',
}) => {
  return (
    <Svg
      className={`${className} ${direction}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0893 3.57745C13.4147 3.90289 13.4147 4.43053 13.0893 4.75596L7.84522 10L13.0893 15.2441C13.4147 15.5696 13.4147 16.0972 13.0893 16.4226C12.7639 16.7481 12.2362 16.7481 11.9108 16.4226L6.07745 10.5893C5.75201 10.2639 5.75201 9.73622 6.07745 9.41078L11.9108 3.57745C12.2362 3.25201 12.7639 3.25201 13.0893 3.57745Z"
      />
    </Svg>
  )
}
Chevron.displayName = 'Chevron'
