import { pxToRem } from '@style-space/components/src/lib/utils'
import styled, { css } from 'styled-components'
import { Button } from '@style-space/components/src/Button'

import { Arrow } from '../icons/Arrow'

export const Wrap = styled.div`
  display: flex;
  align-items: center;
`

export const SButton = styled(Button)(
  ({ theme }) => css`
    && {
      display: flex;
      justify-content: center;
      background-color: transparent;
      box-shadow: none;
      margin-right: ${pxToRem(22)};
      padding: ${pxToRem(4, 8)};
      cursor: pointer;
      border: ${pxToRem(2)} solid transparent;
      border-radius: ${theme.radius.small};

      :focus {
        outline: none;
      }

      :focus-visible {
        border-color: ${theme.color.primary['500']};
      }

      :disabled {
        background-color: transparent;
        border: ${pxToRem(2)} solid transparent;
        opacity: 0.5;
        cursor: initial;
      }

      svg {
        margin: 0 auto;
      }
    }
  `,
)

export const SArrow = styled(Arrow)`
  width: 1rem;
  height: auto;
`
