import styled, { css } from 'styled-components'
import { Heading } from '@style-space/components/src/Heading'
import { pxToRem } from '@style-space/components/src/lib/utils/style'

import { Pager } from '../Pager/Pager'
import { SessionGalleryItem } from './SessionGalleryItem/SessionGalleryItem'

const VISIBLE = 4
export const Section = styled.section(
  ({ theme }) => css`
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    max-width: ${pxToRem(1288)};
    min-height: ${pxToRem(460)};
    padding: ${pxToRem(96, 20, 96, 0)};

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      min-height: ${pxToRem(390)};
      padding: ${pxToRem(56, 0, 56, 0)};
    }
  `,
)

export const Line = styled.div(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(8)};
    width: ${pxToRem(20)};
    height: ${pxToRem(2)};
    background-color: ${theme.color.primary['500']};
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    && {
      margin: 0;
      color: ${theme.color.grey['900']};
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        font-size: ${pxToRem(28)};
      }
    }
  `,
)

export const Description = styled.div(
  ({ theme }) => css`
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    margin-left: ${pxToRem(20)};
    color: ${theme.color.grey['500']};
  `,
)

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${pxToRem(20)};
  margin-bottom: ${pxToRem(12)};
`

export const ScrollWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    margin-top: ${pxToRem(48)};
    padding-top: 38%;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding-top: 100%;
    }
  `,
)

export const RatioWrap = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    padding-left: ${pxToRem(20)};
    > * {
      margin-right: ${pxToRem(30)};
      :last-of-type {
        margin-right: 0;
      }
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      overflow: auto;
      transform: none;
      transition: none;
      -ms-overflow-style: none;
      margin-top: ${pxToRem(32)};
      ::-webkit-scrollbar {
        display: none;
      }
    }
  `,
)

export const InnerWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    display: grid;
    grid-template-columns: repeat(${VISIBLE}, minmax(0, 1fr));
    grid-column-gap: ${pxToRem(30)};
    flex: none;
    transition: 600ms transform ease-in-out;
    width: 100%;
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      grid-column-gap: ${pxToRem(16)};
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      margin-right: 0;
      transform: translateX(0) !important;
      transition: none;
      grid-column-gap: 0;
      ${({ amount }: { amount: number }) => css`
        width: ${62 * Math.min(VISIBLE, amount)}%;
        grid-template-columns: repeat(
          ${Math.min(VISIBLE, amount)},
          minmax(0, 1fr)
        );
      `}
    }
  `,
)

export const SPager = styled(Pager)(
  ({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)

export const SSessionItem = styled(SessionGalleryItem)(
  ({ theme }) => css`
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      width: calc(100% - ${pxToRem(16)});
      margin-right: ${pxToRem(16)};
    }
  `,
)
