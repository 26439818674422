import { StaticImageData } from 'next/image'
import React from 'react'
import { useTheme } from 'styled-components'

import { pxToRem } from '../../../../utils/utils'
import {
  Wrap,
  Title,
  Text,
  SLinkButton,
  SNextImage,
} from './WhatWeOfferItem.style'

type Props = {
  title: string
  text: string
  href?: string
  src: StaticImageData
  alt: string
  className?: string
}

export const WhatWeOfferItem: React.FC<Props> = ({
  className,
  src,
  href,
  alt,
  title,
  text,
}) => {
  const { maxScreenPx, maxScreen } = useTheme()
  return (
    <Wrap data-testid="offerItem" className={className}>
      <SNextImage
        className="image"
        alt={alt}
        placeholder="blur"
        data-testid="offerItemImage"
        src={src}
        sizes={`(max-width: ${maxScreen}) 30vw, ${pxToRem(maxScreenPx / 3)}`}
      />
      <Title
        headingType="style4"
        type="h6"
        className="title"
        data-testid="offerItemTitle"
      >
        {title}
      </Title>
      <Text className="text" data-testid="offerItemText">
        {text}
      </Text>
      {href && (
        <SLinkButton
          nextLink
          href={href}
          data-testid="offerItemHref"
          anchorType="button secondary regular"
          className="button"
        >
          TRY IT OUT
        </SLinkButton>
      )}
    </Wrap>
  )
}
WhatWeOfferItem.displayName = 'WhatWeOfferItem'
