import React from 'react'
import styled, { css } from 'styled-components'

const Svg = styled.svg(
  ({ theme }) => css`
    path {
      fill: ${theme.color.grey['500']};
    }

    &.white {
      path {
        fill: ${theme.color.white};
      }
    }

    &.right {
      transform: rotate(180deg);
    }
  `,
)

type Props = {
  white?: boolean
  right?: boolean
  className?: string
}

export const Arrow: React.FC<Props> = ({ className = '', right, white }) => {
  const rightClass = right ? 'right' : ''
  const whiteClass = white ? 'white' : ''
  return (
    <Svg
      className={`${rightClass} ${whiteClass} ${className}`}
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.63388 6.33333H14.875C15.2202 6.33333 15.5 6.07217 15.5 5.75C15.5 5.42783 15.2202 5.16667 14.875 5.16667H2.63388L6.56694 1.49581C6.81102 1.26801 6.81102 0.89866 6.56694 0.670854C6.32286 0.443049 5.92714 0.443049 5.68306 0.670854L0.683058 5.33752C0.43898 5.56533 0.43898 5.93467 0.683058 6.16248L5.68306 10.8291C5.92714 11.057 6.32286 11.057 6.56694 10.8291C6.81102 10.6013 6.81102 10.232 6.56694 10.0042L2.63388 6.33333Z" />
    </Svg>
  )
}
Arrow.displayName = 'Arrow'
