import { pxToRem } from '@style-space/components/src/lib/utils'
import styled, { css } from 'styled-components'

import { Pager } from '../../Pager'

export const Wrap = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: ${pxToRem(711)};
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      max-width: 100%;
    }
  `,
)

export const SPager = styled(Pager)(
  ({ theme }) => css`
    width: 89%;
    margin-top: ${pxToRem(25)};
    justify-content: center;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      margin-top: ${pxToRem(33)};
      margin-left: 50%;
      transform: translateX(-50%);
    }
  `,
)
