import styled, { css } from 'styled-components'
import { Heading } from '@style-space/components/src/Heading'
import { pxToRem } from '@style-space/components/src/lib/utils/style'
import { S3NextImage } from '@style-space/components/src/S3NextImage'
import NextLink from 'next/link'

import { RatioWrap } from '../../RatioWrap'

export const Link = styled(NextLink)(
  ({ theme }) => css`
    position: relative;
    display: block;
    overflow: hidden;
    width: ${pxToRem(225)};
    text-decoration: none;
    border-radius: ${theme.radius.small};
    :focus {
      outline: none;
    }
    :focus-visible {
      box-shadow: ${theme.boxShadow.focusBoxShadow};
    }
  `,
)

export const SRatioWrap = styled(RatioWrap)`
  margin-bottom: ${pxToRem(8)};
`

export const SS3NextImage = styled(S3NextImage)(
  ({ theme }) => css`
    border-radius: ${theme.radius.small};
    overflow: hidden;
  `,
)

export const Category = styled.h5(
  ({ theme }) => css`
    margin: 0;
    width: 100%;
    text-align: left;
    font-weight: ${theme.font.weight.regular};
    font-size: ${pxToRem(14)};
    color: ${theme.color.grey['500']};
    line-height: 1.43;
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    margin: 0;
    width: 100%;
    text-align: left;
    color: ${theme.color.grey['900']};
    font-weight: ${theme.font.weight.regular};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      && {
        font-size: ${pxToRem(16)};
      }
    }
  `,
)

export const DataWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${pxToRem(8)};
`

export const DataInner = styled.div`
  display: flex;
  align-items: center;
`

export const Price = styled.div(
  ({ theme }) => css`
    margin-right: ${pxToRem(4)};
    color: ${theme.color.grey['900']};
    font-weight: ${theme.font.weight.bold};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
  `,
)

export const PriceInfo = styled.div(
  ({ theme }) => css`
    line-height: 1;
    color: ${theme.color.grey['500']};
    font-size: ${pxToRem(14)};
  `,
)
