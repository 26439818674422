import React from 'react'

import { Wrap, SStar, Value, Amount } from './Rating.style'

type Props = {
  value: number
  amount: number
  className?: string
}

export const Rating: React.FC<Props> = ({ className, value, amount }) => {
  return (
    <Wrap data-testid="rating" className={className}>
      <SStar />
      <Value data-testid="ratingValue">{Math.round(value * 100) / 100}</Value>
      <Amount data-testid="ratingAmount">({amount})</Amount>
    </Wrap>
  )
}
Rating.displayName = 'Rating'
