import React from 'react'
import { NextSeo } from 'next-seo'

import { PUBLIC_BASE_URL } from '../lib/utils'
import { capTextWithEllipsis } from '../lib/utils/string'

export const TITLE = 'Style Space || Discover your style'
export const DESCRIPTION =
  'Style Space is a virtual styling and beauty service that connects you 1-on-1 with expert personal stylists to look and feel your best.'
export const PREVIEW_ASPECT = 1.91
export const DESCRIPTION_CAP = 180

export const DEFAULT_SEO_IMAGES = [
  {
    url: `${PUBLIC_BASE_URL}/images/preview-1200.jpeg`,
    width: 1200,
    height: Math.floor(1200 / PREVIEW_ASPECT),
    alt: 'StyleSpace page preview',
  },
  {
    url: `${PUBLIC_BASE_URL}/images/preview-800.jpeg`,
    width: 800,
    height: Math.floor(800 / PREVIEW_ASPECT),
    alt: 'StyleSpace page preview 2',
  },
  {
    url: `${PUBLIC_BASE_URL}/images/preview-300x300.jpeg`,
    width: 300,
    height: 300,
    alt: 'StyleSpace page preview 1x1',
  },
]

type Props = {
  title?: string
  description?: string
  url?: string
  disabled?: boolean
  images?: {
    url: string
    width: number
    height: number
    alt: string
  }[]
  article?: {
    publishedTime: string
    modifiedTime: string
    authors: string[]
  }
}

export const Seo: React.FC<Props> = ({
  disabled = false,
  title,
  url,
  description,
  images,
  article,
}) => {
  return (
    <NextSeo
      title={title || TITLE}
      description={capTextWithEllipsis(
        description || DESCRIPTION,
        DESCRIPTION_CAP,
      )}
      canonical={url || PUBLIC_BASE_URL}
      noindex={disabled}
      nofollow={disabled}
      openGraph={{
        type: article ? 'article' : 'website',
        locale: 'en_IE',
        title: title || TITLE,
        description: capTextWithEllipsis(
          description || DESCRIPTION,
          DESCRIPTION_CAP,
        ),
        url: url || PUBLIC_BASE_URL,
        site_name: 'StyleSpace',
        images: images || DEFAULT_SEO_IMAGES,
        article,
      }}
    />
  )
}
Seo.displayName = 'Seo'
