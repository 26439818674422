import styled, { css } from 'styled-components'
import { Heading } from '@style-space/components/src/Heading'
import { pxToRem } from '@style-space/components/src/lib/utils/style'

export const Wrap = styled.div(
  ({ theme }) => css`
    position: relative;
    margin: 0 auto;
    max-width: ${pxToRem(1244)};
    width: 100%;
    padding: ${pxToRem(96, 20)};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding: ${pxToRem(56, 20)};
    }
  `,
)

export const STitle = styled(Heading)(
  ({ theme }) => css`
    && {
      width: 100%;
      text-align: center;
      margin: ${pxToRem(0, 0, 12, 0)};
      color: ${theme.color.grey['900']};
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        font-size: ${pxToRem(28)};
      }
    }
  `,
)

export const Text = styled.p(
  ({ theme }) => css`
    width: 100%;
    max-width: ${pxToRem(684)};
    margin: 0 auto;
    text-align: center;
    color: ${theme.color.grey['500']};
    font-weight: ${theme.font.weight.regular};
    font-size: ${pxToRem(16)};
    margin-bottom: ${pxToRem(48)};
    line-height: 1.5;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      margin-bottom: ${pxToRem(32)};
    }
  `,
)

export const OfferItemWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;

    > *:not(:last-child) {
      margin-right: ${pxToRem(12)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: flex;
      flex-direction: column;

      > * {
        margin-right: 0;
      }
    }
  `,
)
