import styled, { css } from 'styled-components'
import { Heading } from '@style-space/components/src/Heading'
import { Anchor } from '@style-space/components/src/Anchor'
import NextImage from 'next/image'

import { pxToRem } from '../../../../utils/utils'

export const Wrap = styled.div(
  ({ theme }) => css`
    text-decoration: none;
    position: relative;
    max-width: ${pxToRem(348)};
    width: 100%;
    height: 100%;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      align-items: flex-start;
      max-width: 100%;
      margin-bottom: ${pxToRem(32)};
    }
  `,
)

export const SNextImage = styled(NextImage)(
  ({ theme }) => css`
    border-radius: ${theme.radius.small};
    overflow: hidden;
    width: 100%;
    height: auto;
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    color: ${theme.color.grey['900']};
    margin: ${pxToRem(24, 0, 12)};
    width: 100%;
    text-align: center;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      margin: ${pxToRem(16, 0)};
      && {
        font-size: ${pxToRem(18)};
      }
    }
  `,
)

export const SLinkButton = styled(Anchor)(
  ({ theme }) => css`
    margin: 0 auto;
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      && {
        padding: ${pxToRem(8, 2)};
        max-width: ${pxToRem(90)};
        font-size: ${pxToRem(14)};
      }
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      && {
        margin: 0;
        max-width: 100%;
        width: 100%;
      }
    }
  `,
)

export const Text = styled.p(
  ({ theme }) => css`
    color: ${theme.color.grey['500']};
    font-weight: ${theme.font.weight.regular};
    font-size: ${pxToRem(16)};
    margin: 0 0 ${pxToRem(24)};
    line-height: 1.5;
    width: 100%;
    text-align: center;
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      display: none;
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      max-width: ${pxToRem(75)};
      text-align: left;
    }
  `,
)
