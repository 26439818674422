import React from 'react'
import { Category } from '@style-space/components/src/types'
import { capTextWithEllipsis } from '@style-space/components/src/lib/utils/string'

import { CATEGORY_STYLIST } from '../../../../../utils/constants'
import {
  Wrap,
  InnerWrap,
  ImageWrapWrap,
  ImageWrap,
  SStylistImage,
  Title,
  DataWrap,
  Description,
  MoreAnchor,
} from './StylistSectionItemDisplay.style'

type Props = {
  avatar: string
  category: Category
  fullName: string
  href: string
  about: string
  className?: string
}

export const StylistSectionItemDisplay: React.FC<Props> = ({
  className,
  avatar,
  category,
  fullName,
  href,
  about,
}) => {
  return (
    <Wrap data-testid="StylistSectionItemDisplay" className={className}>
      <InnerWrap>
        <ImageWrapWrap>
          <ImageWrap>
            <SStylistImage
              key={avatar}
              image={avatar}
              subtitle={`${CATEGORY_STYLIST[category]}`}
              title={fullName}
              href={href}
            />
          </ImageWrap>
        </ImageWrapWrap>
        <DataWrap>
          <Title
            headingType="style3"
            type="h3"
            data-testid="StylistSectionItemDisplayTitle"
          >
            {fullName}
          </Title>
          <Description data-testid="StylistSectionItemDisplayDescription">
            {capTextWithEllipsis(about, 350)}{' '}
            <MoreAnchor
              href={href}
              nextLink
              anchorType="inline"
              underline
              data-testid="StylistSectionItemDisplayMore"
            >
              See more
            </MoreAnchor>
          </Description>
        </DataWrap>
      </InnerWrap>
    </Wrap>
  )
}
StylistSectionItemDisplay.displayName = 'StylistSectionItemDisplay'
