import React from 'react'

import { Wrap, SButton, SArrow } from './Pager.style'

type Props = {
  white?: boolean
  onNext?(): void
  onPrev?(): void
  className?: string
}

export const Pager: React.FC<Props> = ({
  white,
  className,
  onNext,
  onPrev,
}) => {
  return (
    <Wrap data-testid="pager" className={`pager ${className}`}>
      <SButton
        data-testid="pagerOnPrev"
        className={'pager-btn pager-prev'}
        onClick={onPrev}
        disabled={!Boolean(onPrev)}
      >
        <SArrow white={white} />
      </SButton>
      <SButton
        data-testid="pagerOnNext"
        className={'pager-btn pager-next'}
        onClick={onNext}
        disabled={!Boolean(onNext)}
      >
        <SArrow white={white} right />
      </SButton>
    </Wrap>
  )
}
Pager.displayName = 'Pager'
