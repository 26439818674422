import styled from 'styled-components'

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const Wrap2 = styled.div`
  position: relative;
  width: 100%;
  padding-top: var(--ratio);

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
