import OriginNextLink from 'next/link'
import React, { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  href?: string
  className?: string
  replace?: boolean
}>

export const NextLink: React.FC<Props> = ({
  children,
  href,
  replace,
  ...rest
}) => {
  if (href) {
    return (
      <OriginNextLink href={href} replace={replace} {...rest}>
        {children}
      </OriginNextLink>
    )
  }
  return (
    <a href={href} {...rest}>
      {children}
    </a>
  )
}
