import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'

import HeroImage from '../../../../public/images/hero.png'
import {
  AnchorText,
  Backgroud,
  BigText,
  ImageWrap,
  InnerWrap,
  SAnchor,
  SChevron,
  SImage,
  Section,
  TextWrap,
  Underline,
} from './Hero.style'
import { pxToRem } from '../../../utils/utils'
import { ROUTES } from '../../../utils/routes'

type Props = PropsWithChildren<{
  className?: string
}>

export const Hero: React.FC<Props> = ({ className, children }) => {
  const {
    breakpoint: { tabletV },
    maxScreen,
  } = useTheme()
  return (
    <Section data-testid="hero" className={className}>
      <Backgroud />
      <InnerWrap>
        <TextWrap>
          <h1>
            <BigText>1-on-1 expert</BigText>
            <BigText>personal styling,</BigText>
            <BigText>done virtually</BigText>
          </h1>
          <SAnchor href={ROUTES.sessionMatching} nextLink uppercase={false}>
            <Underline className="underline" />
            <AnchorText>Take your style matching quiz</AnchorText>
            <SChevron direction="right" />
          </SAnchor>
        </TextWrap>
        <ImageWrap>
          <SImage
            className={className}
            data-testid="hero-image"
            src={HeroImage}
            alt=""
            sizes={`(max-width: ${tabletV}) ${pxToRem(
              400,
            )}, (max-width: ${maxScreen}) ${pxToRem(600)}, ${pxToRem(800)}`}
          />
        </ImageWrap>
      </InnerWrap>
      {children}
    </Section>
  )
}
Hero.displayName = 'Hero'
