import React from 'react'

import { WhatWeOfferItem } from './WhatWeOfferItem/WhatWeOfferItem'
import { Wrap, STitle, Text, OfferItemWrap } from './WhatWeOffer.style'
import WardrobeImage from '../../../../public/images/offer-wardrobe.jpg'
import HairImage from '../../../../public/images/offer-hair.jpg'
import MakeupImage from '../../../../public/images/offer-makeup.jpg'

type Props = {
  className?: string
  title: string
  text: string
  item1Data: {
    title: string
    text: string
    href: string
  }
  item2Data: {
    title: string
    text: string
    href: string
  }
  item3Data: {
    title: string
    text: string
    href: string
  }
}

export const WhatWeOffer: React.FC<Props> = ({
  className,
  title,
  text,
  item1Data,
  item2Data,
  item3Data,
}) => {
  return (
    <Wrap data-testid="whatWeOffer" className={className}>
      <STitle headingType="style2" type="h2" data-testid="whatWeOfferTitle">
        {title}
      </STitle>
      <Text data-testid="whatWeOfferText">{text}</Text>
      <OfferItemWrap>
        <WhatWeOfferItem
          data-testid="whatWeOffer1Data"
          alt=""
          href={item1Data.href}
          src={WardrobeImage}
          title={item1Data.title}
          text={item1Data.text}
        />
        <WhatWeOfferItem
          alt=""
          href={item2Data.href}
          src={HairImage}
          title={item2Data.title}
          text={item2Data.text}
        />
        <WhatWeOfferItem
          alt=""
          href={item3Data.href}
          src={MakeupImage}
          title={item3Data.title}
          text={item3Data.text}
        />
      </OfferItemWrap>
    </Wrap>
  )
}
WhatWeOffer.displayName = 'WhatWeOffer'
