import React from 'react'

export const usePagerIndex = (amount: number, linear: boolean = false) => {
  const [index, setIndex] = React.useState(0)
  const onNext = React.useCallback(() => {
    if (amount === 0) {
      return
    }
    setIndex((prev) => {
      return (prev + 1) % amount
    })
  }, [setIndex, amount])

  const onPrev = React.useCallback(() => {
    if (amount === 0) {
      return
    }
    setIndex((prev) => {
      const newIndex = prev - 1
      if (newIndex < 0) {
        return amount - 1
      }
      return newIndex
    })
  }, [setIndex, amount])
  if (linear) {
    return {
      index,
      onPrev: index > 0 ? onPrev : undefined,
      onNext: index < amount - 1 ? onNext : undefined,
    }
  }
  return {
    index,
    onPrev,
    onNext,
  }
}
