import styled, { css } from 'styled-components'
import { Popover } from '@headlessui/react'
import { FormikRadioGroup } from '@style-space/components/src/Form/Radio/RadioGroup/FormikRadioGroup'
import { pxToRem } from '@style-space/components/src/lib/utils'
import { Form } from '@style-space/components/src/Form'
import { XIcon as X } from '@style-space/components/src/icons/XIcon'
import { Button } from '@style-space/components/src/Button'

import { Magnifier } from '../../common/icons/Magnifier'

export const PopoverItems: typeof Popover.Panel = styled(Popover.Panel)(
  ({ theme }) => css`
    position: fixed;
    overflow: hidden;
    z-index: 999;
    bottom: 0;
    background-color: ${theme.color.white};
    padding: ${pxToRem(24, 20)};
    box-shadow: 0 0 ${pxToRem(60)} rgba(0, 0, 0, 0.3);
    border-radius: ${pxToRem(24, 24, 0, 0)};
    height: auto;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);

    &.enter {
      transition:
        max-height 450ms,
        max-width 150ms,
        left 150ms,
        bottom 150ms,
        border-radius 150ms;
    }

    &.enter-from {
      border-radius: ${pxToRem(24)};
      max-width: ${pxToRem(500)};
      max-height: ${pxToRem(56)};
      bottom: ${pxToRem(20)};

      > * {
        opacity: 0;
      }
    }

    &.enter-to {
      border-radius: ${pxToRem(24, 24, 0, 0)};
      max-height: 100%;
      > * {
        opacity: 1;
      }
    }
  `,
) as any

export const XIcon = styled(X)`
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const XButton = styled.button(
  ({ theme }) => css`
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    border: 0.125rem solid transparent;
    background-color: transparent;
    box-shadow: none;
    top: 0;
    right: 0;
    z-index: 1;

    :focus,
    :active {
      outline: none;
    }

    :focus-visible {
      border: 0.125rem solid ${theme.color.dark};
    }
  `,
)

export const PopoverButtonText = styled.span(
  ({ theme }) => css`
    font-family: ${theme.font.mukta};
    color: ${theme.color.medium};
    line-height: 1.33;
    font-size: ${pxToRem(18)};
    background: linear-gradient(
      86.22deg,
      #28388c 0%,
      #3091b8 46.87%,
      #72288c 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* stylelint-disable-next-line property-no-unknown */
    text-fill-color: transparent;
  `,
) as any

export const SPopoverButton: typeof Popover.Button = styled(Popover.Button)(
  ({ theme }) => css`
    display: none;
    padding: ${pxToRem(16, 8)};
    cursor: pointer;
    overflow: hidden;
    height: ${pxToRem(56)};
    width: calc(100% - ${pxToRem(40)});
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: ${pxToRem(20)};
    left: 50%;
    max-width: ${pxToRem(500)};
    border: none;
    z-index: 10;
    transform: translateX(-50%);
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.default};
    border-radius: ${pxToRem(28)};

    &.open {
      opacity: 0;
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: flex;
    }
  `,
) as any

export const Background = styled.div(
  ({ theme }) => css`
    height: ${pxToRem(120)};
    width: 100%;
    z-index: 10;
    display: none;
    position: fixed;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0.3;

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: block;
    }
  `,
)

export const Counter = styled.div(
  ({ theme }) => css`
    height: ${pxToRem(20)};
    margin-right: ${pxToRem(8)};
    width: ${pxToRem(20)};
    font-size: ${pxToRem(12)};
    border-radius: 50%;
    background-color: ${theme.color.orange};
    color: ${theme.color.white};
  `,
)

export const Title = styled.h5(
  ({ theme }) => css`
    white-space: nowrap;
    color: ${theme.color.dark};
    font-size: ${pxToRem(18)};
    font-family: ${theme.font.poppins};
    line-height: 1.2;
    flex: none;
    font-weight: ${theme.font.weight.medium};
    margin: ${pxToRem(0, 0, 24)};
    background: linear-gradient(
      86.22deg,
      #28388c 0%,
      #3091b8 46.87%,
      #72288c 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* stylelint-disable-next-line property-no-unknown */
    text-fill-color: transparent;
  `,
)

export const SButton = styled(Button)(
  ({ theme }) => css`
    position: relative;
    overflow: hidden;
    && {
      color: ${theme.color.white};
      width: 100%;
      height: ${pxToRem(54)};
      border-radius: ${theme.radius.medium};
      margin: ${pxToRem(23, 0, 0)};
      background-color: transparent;
      border: none;
      padding: ${pxToRem(0, 20)};

      :hover {
        background-color: transparent;
        border-color: transparent;

        > div {
          animation: anime-hover 1s;
          background-position: 100% 50%;
        }
      }

      .small & {
        width: ${pxToRem(246)};
        height: ${pxToRem(56)};
      }

      @keyframes anime-hover {
        0% {
          background-position: 0 50%;
        }
        100% {
          background-position: 100% 50%;
        }
      }
    }
  `,
)

export const ButtonText = styled.span`
  font-size: inherit;
  color: inherit;
  position: relative;
  line-height: 1;
  z-index: 1;
`

export const SMagnifier = styled(Magnifier)`
  position: relative;
  top: ${pxToRem(-1)};
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  margin-right: ${pxToRem(8)};
  flex: none;
  stroke: #294191;
  stroke-width: ${pxToRem(2)};
`

export const ButtonBg = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    86.22deg,
    #28388c 0%,
    #3091b8 46.87%,
    #72288c 100%
  );
  animation: anime 16s linear infinite;
  background-size: 600%;

  @keyframes anime {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`

export const SForm: typeof Form = styled(Form)`
  position: relative;
  width: 100%;
  height: 100%;
` as any

export const SFormikRadioGroup = styled(FormikRadioGroup)(
  ({ theme }) => css`
    margin-bottom: ${pxToRem(24)};
    fieldset {
      margin-top: 0;
    }

    .radio-group-label {
      font-size: ${pxToRem(16)};
      color: ${theme.color.grey['900']};
      font-weight: ${theme.font.weight.regular};
    }

    .radio-group-container {
      margin: ${pxToRem(4, 0, 0, 0)};
    }

    .radio-input {
      margin: 0;

      label {
        margin-right: ${pxToRem(12)};
      }
      :last-of-type {
        label {
          margin-right: 0;
        }
      }
    }
  `,
)
