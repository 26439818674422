import React from 'react'
import { useTheme } from 'styled-components'
import { StaticImageData } from 'next/image'

import { Wrap, Title, Text, SNextImage } from './HowItWorksItem.style'
import { pxToRem } from '../../../../utils/utils'

type Props = {
  title: string
  text: string
  src: StaticImageData
  className?: string
}

export const HowItWorksItem: React.FC<Props> = ({
  className,
  src,
  title,
  text,
}) => {
  const { maxScreenPx } = useTheme()
  return (
    <Wrap data-testid="HowItWorksItem" className={className}>
      <SNextImage
        data-testid="HowItWorksItemImage"
        src={src}
        alt=""
        sizes={pxToRem(maxScreenPx / 3)}
      />
      <Title headingType="style4" type="h3" data-testid="HowItWorksItemTitle">
        {title}
      </Title>
      <Text data-testid="HowItWorksItemText">{text}</Text>
    </Wrap>
  )
}
HowItWorksItem.displayName = 'HowItWorksItem'
