import { pxToRem } from '@style-space/components/src/lib/utils'
import styled, { css } from 'styled-components'

import { SearchBar } from '../SearchBar'

export const SSearchBar = styled(SearchBar)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${pxToRem(-40)};
    left: 50%;
    z-index: 5;
    transform: translateX(-50%);

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: none;
    }
  `,
)
