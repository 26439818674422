import { usePaginatedQuery } from '@style-space/components/src/lib/graphql/usePaginatedQuery'

import { Category, FeaturedStylist } from '../../../../@types/types'

const QUERY = `
  query GetFeaturedStylists($amount: Int!, $offset: Int!) {
    field: featuredStylists(pagination: { count: $amount, offset: $offset }) {
      results {
        id
        slug
        stylistPublicProfile {
          fullName
          avatar
          about
          category: specialty
        }
      }
      paginationInfo {
        hasMore
        totalResults
      }
    }
  }
`

type Data = {
  id: string
  slug: string
  stylistPublicProfile: {
    fullName: string
    avatar: string
    about: string
    category: Category
  }
}

type FeaturedStylistData = {
  field: {
    results: Data
    paginationInfo: {
      hasMore: boolean
      totalResults: number
    }
  }
}

export const useFeaturedStylist = (amount: number = 10) => {
  const { data, ...rest } = usePaginatedQuery<Data, FeaturedStylistData>(
    QUERY,
    {
      amount,
    },
    (item) => item?.field,
  )

  return {
    data: data?.map((item) => ({
      ...item,
      ...item.stylistPublicProfile,
    })) as FeaturedStylist[],
    ...rest,
  }
}
