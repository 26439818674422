import React, { PropsWithChildren } from 'react'

import { Wrap, Wrap2 } from './RatioWrap.style'

type Props = PropsWithChildren<{
  ratio: number
  className?: string
}>

export const RatioWrap: React.FC<Props> = ({ ratio, children, className }) => {
  const paddingStyle = {
    '--ratio': `${ratio}%`,
  } as any
  return (
    <Wrap data-testid="ratioWrap" className={`ratio-wrap ${className}`}>
      <Wrap2 data-testid="ratioWrapInner" style={paddingStyle}>
        {children}
      </Wrap2>
    </Wrap>
  )
}
RatioWrap.displayName = 'RatioWrap'
