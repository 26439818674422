import { Heading } from '@style-space/components/src/Heading'
import styled, { css } from 'styled-components'
import { Anchor } from '@style-space/components/src/Anchor/Anchor'
import { pxToRem } from '@style-space/components/src/lib/utils/style'

export const Wrap = styled.div(
  ({ theme }) => css`
    position: relative;
    margin: ${pxToRem(0, 'auto', 0)};
    width: 100%;
    padding: ${pxToRem(96, 20)};
    background: ${theme.color.grey['800']};
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding: ${pxToRem(56, 20)};
    }
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    && {
      margin: ${pxToRem(0, 0, 48)};
      width: 100%;
      color: ${theme.color.white};
      text-align: center;
      @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
        margin: ${pxToRem(0, 0, 32)};
        font-size: ${pxToRem(28)};
      }
    }
  `,
)

export const ItemWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: ${pxToRem(1204)};

    > *:not(:last-child) {
      margin-right: ${pxToRem(12)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      display: flex;
      flex-direction: column;

      > * {
        margin-right: 0;
      }
    }
  `,
)

export const LinkButton = styled(Anchor)(
  ({ theme }) => css`
    margin: ${pxToRem(48)} auto 0;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      && {
        margin-top: ${pxToRem(32)};
        max-width: 100%;
      }
    }
  `,
)
