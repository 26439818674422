import React from 'react'
import { object, string } from '@style-space/components/src/Form/schema'
import { useRouter } from 'next/router'
import { FormikSubmit } from '@style-space/components/src/Form'

import { getSearchRoute } from '../../../utils/routes'
import { Gender, Category } from '../../../../@types/types'
import {
  InnerWrap,
  SForm,
  Title,
  Wrap,
  ScreenWrap,
  Line,
  SButton,
  ButtonText,
  ButtonBg,
  SFormikRadioGroup,
  SMagnifier,
} from './SearchBar.style'

export const CATEGORY_OPTIONS = [
  { label: 'Clothing', value: 'CLOTHING' },
  { label: 'Hair', value: 'HAIR' },
  { label: 'Makeup/Skincare', value: 'MAKE_UP_SKIN_CARE' },
]
export const GENDER_OPTIONS = [
  { label: 'Any', value: 'ANY' },
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
]

export const SCHEMA = object().shape({
  category: string(),
  gender: string(),
})

export const INIT_VALUES = {
  category: 'CLOTHING' as Category,
  gender: 'ANY' as Gender,
}

export type SearchValues = typeof INIT_VALUES

type Props = {
  id?: string
  small?: boolean
  values?: SearchValues
  className?: string
}

export const SearchBar: React.FC<Props> = ({
  id,
  small,
  values,
  className = '',
}) => {
  const smallClass = small ? 'small' : ''
  const router = useRouter()
  const onSubmit = React.useCallback<FormikSubmit<SearchValues>>(
    async ({ category, gender }: SearchValues) => {
      await router.push(getSearchRoute(category, gender, []))
    },
    [],
  )
  return (
    <Wrap id={id} className={`${smallClass} ${className}`} data-testid="search">
      <InnerWrap>
        <Title>
          Find your
          <br />
          Style Session
        </Title>
        <SForm<SearchValues>
          initialValues={{ ...INIT_VALUES, ...values }}
          validationSchema={SCHEMA}
          customSubmit
          onSubmit={onSubmit}
          data-testid="searchForm"
        >
          <ScreenWrap>
            <Line />
            <SFormikRadioGroup
              popError
              name="category"
              label="Category"
              radioType="chip"
              options={CATEGORY_OPTIONS}
            />
            <Line />
            <SFormikRadioGroup
              popError
              name="gender"
              label="Gender"
              radioType="chip"
              options={GENDER_OPTIONS}
            />
            <SButton data-testid="searchSubmit" type="submit">
              <SMagnifier />
              <ButtonText>Search style session</ButtonText>
              <ButtonBg />
            </SButton>
          </ScreenWrap>
        </SForm>
      </InnerWrap>
    </Wrap>
  )
}
SearchBar.displayName = 'SearchBar'
