import styled, { css } from 'styled-components'
import { Heading } from '@style-space/components/src/Heading'
import { Anchor } from '@style-space/components/src/Anchor'

import { StylistImage } from './StylistImage/StylistImage'
import { pxToRem } from '../../../../../utils/utils'

export const Wrap = styled.div`
  width: 100%;
`

export const InnerWrap = styled.div(
  ({ theme }) => css`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: start;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      flex-direction: column;
    }
  `,
)

export const DataWrap = styled.div(
  ({ theme }) => css`
    margin: auto ${pxToRem(0, 0, 40)};
    height: 100%;
    width: calc(60% - ${pxToRem(40)});
    flex: none;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      margin-left: ${pxToRem(20)};
    }
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      width: 100%;
      margin-left: 0;
    }
  `,
)

export const Title = styled(Heading)(
  ({ theme }) => css`
    && {
      position: relative;
      color: ${theme.color.white};
      line-height: 1;
      margin: ${pxToRem(0, 0, 12)};

      @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
        font-size: ${pxToRem(20)};
      }
    }
  `,
)

export const Description = styled.p(
  ({ theme }) => css`
    color: ${theme.color.grey['300']};
    line-height: 1.5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    font-size: ${pxToRem(16)};
    padding-bottom: 3px;
  `,
)

export const ImageWrapWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 40%;
    flex: none;
    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      width: 100%;
      margin-bottom: ${pxToRem(32)};
    }
  `,
)

export const ImageWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    padding-top: 133%; /* 16:9 Aspect Ratio */
    width: 100%;
    border-radius: ${theme.radius.small};
  `,
)

export const SStylistImage = styled(StylistImage)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
`

export const MoreAnchor = styled(Anchor)(
  ({ theme }) => css`
    display: inline;
    cursor: pointer;
    white-space: nowrap;
    && {
      :focus {
        border-color: ${theme.color.white};
      }
    }
  `,
)
